import logo from '../logo.svg';
import '../App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from '../components/about.js';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import Project from '../components/project.js';
import Algorithms from '../components/algorithms.js';

import IdeaManagerPic from '../pictures/IdeaManagerPic.png';
import SudokuPic from '../pictures/SudokuImage.png';

function Home() {
  return (
    <div className="App">
      <header id='top' className="App-header">
      <Header />
      <Algorithms />
      </header>
      <About id="about"/>
      <h2 id='projects'> Featured Projects</h2>
      <div id='projectContainer'>
        <Project title="Sudoku Save State" type="Phone App" description="Sudoku with a save state feature for easy variable checking" image={SudokuPic} link="https://play.google.com/store/apps/details?id=com.shockedwhistle.SudokuSaveState&pli=1"/>
        <Project title="Idea Manager" type="Phone App" description="A phone app to manage to do lists, take notes, and display relevant images." image={IdeaManagerPic} link='https://play.google.com/store/apps/details?id=com.shockedwhistle.Test2'/>
      </div>
      <h2 id='contact'>Contact</h2>
      <Footer />
    </div>
  );
}

export default Home;
