import '../App.css';

import githubWhite from '../pictures/github-mark-white.png';
import github from '../pictures/github-mark.png';
import linkedin from '../pictures/In-Blue-128.png';


function Footer() {
    return ( 
        <div id='footer' className='contact'>
            <h4 id='footerSpacer'>Top</h4>
            <h4 className='contacts'>DarrellCrochet3@gmail.com</h4>
            <li className='contacts'><a href='https://github.com/ShockedWhistle'><img src={githubWhite}/> <label>GitHub</label></a></li>
            <li className='contacts'><a href='https://www.linkedin.com/in/darrell-crochet-694477236/'><img src={linkedin}/> <label>Linkedin</label></a></li>


            <h4 className='contacts' id='toTop'><a href='#top'>Top</a></h4>
        </div>
     );
}

export default Footer;