import logo from '../logo.svg';
import React, {useState, size, timeBetween, useEffect, button} from 'react';
import Dropdown from 'react-dropdown';

import '../App.css';

function Algorithms() {
  const [size, setSize] = useState(100);
  const [timeBetween, setTimeBetween] = useState(40);
  const [array, setArray] = useState(shuffle(size));
  const [isSorted, setIsSorted] = useState(false);
  const [displaySorted, setDisplaySorted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [runAlgorithm, setRunAlgorithm] = useState(null);
  const [loop, setLoop] = useState(true);
  const [algorithm, setAlgorithm] = useState(null);

  const options = ['Bubble Sort', 'Selection Sort', 'Insertion Sort', 'Merge Sort'];

  if(algorithm == null){
    const randomAlgorithm = Math.floor(Math.random() * (options.length));
    setAlgorithm(options[randomAlgorithm]);
    
    console.log(randomAlgorithm);
    console.log(algorithm);
  }

  let index = 0;
  
  useEffect(() => {
    console.log("Effect");

    if(!isSorted && isPlaying){ // Run algorithm
      switch(algorithm){
        case options[0]: // Bubble Sort
        console.log(options[0]);
          setRunAlgorithm(setInterval(() => {
            index = bubbleSort(runAlgorithm);
            displayArray();
          }, timeBetween));
          break;
        case options[1]: // Selection Sort
        console.log(options[1]);
        setRunAlgorithm(setInterval(() => {
          selectionSort();
          index++;
          if(index >= array.length){ // Finished
            console.log("Selection Sorted");
            clearInterval(runAlgorithm);
            setIsSorted(true);
            setIsPlaying(false);
            setDisplaySorted(true);
          }
          displayArray();
        }, timeBetween));
        break;
        case options[2]: // Insertion Sort
        console.log(options[2]);
        setRunAlgorithm(setInterval(() => {
          insertionSort();
          index++;
          if(index >= array.length){ // Finished
            console.log("Insertion Sorted");
            clearInterval(runAlgorithm);
            setIsSorted(true);
            setIsPlaying(false);
            setDisplaySorted(true);
          }
          displayArray();
        }, timeBetween));
        break;
        case options[3]: // Merge Sort
        console.log(options[3]);
        displayArray();
        let mergeArr = [];
        for(let i=0; i<array.length; i++){ // Iterates through array separating everything to its own array
          //console.log(i);
          mergeArr.push([array[i]]);
        }

        //console.log("Raw: ", mergeArr);
        setRunAlgorithm(setInterval(() => {
          //console.log("Check: ", mergeArr);
          if(mergeArr[0].length == size){ // Finished
            console.log("Merge Sorted", mergeArr);
            setArray(mergeArr[0]);
            clearInterval(runAlgorithm);
            setIsSorted(true);
            setIsPlaying(false);
            setDisplaySorted(true); 
          }
          else{
            mergeArr = mergeSort(mergeArr);
            displayNestedArray(mergeArr);
          }

        }, timeBetween));
        break;
        case options[4]: // Quick Sort
          break;
      }
    }
    else if(displaySorted){ // Run finishing animation
      //displayArray();
      clearInterval(runAlgorithm);
      setTimeout(() => {
        var finishAlgorithm = setInterval(() => {
          displaySortedArray(finishAlgorithm);
        }, (timeBetween));
      }, timeBetween);
    }
    else{
      console.log("Success");
      if(loop){
        setTimeout(() => {
          setArray(shuffle(size));
          setIsSorted(false);
          setIsPlaying(true);
          index = 0;
        }, timeBetween * 36)
      }
    }
  }, [isSorted, isPlaying, displaySorted, array]);

  function shuffle(quantity) {
    let tempArray = [];

    for(let i=1; i <= quantity; i++){
      tempArray.push(i);
    }
  
    let index = quantity;
    while(index != 0){
      let rando = Math.floor(Math.random() * index);
      index--;
  
      [tempArray[index], tempArray[rando]] = [
        tempArray[rando], tempArray[index]
      ];
    }
  
    return tempArray;
  }

  function displayArray(){
    const canvas = document.getElementById("algorithmCanvas");
    const context = canvas.getContext("2d");
    const maxX = canvas.width;
    const maxY = canvas.height;
    let gap = 2;

    context.beginPath();
    context.moveTo(0,0);
    context.clearRect(0, 0, canvas.width, canvas.height);

    context.strokeStyle = "black";
  
    let minHeight = (maxY - gap) / array.length;
    let width = (maxX  - ((array.length + 2) * gap)) / array.length ;
    context.lineWidth = width;
    let x = (width / 2) + gap + gap;
    let h = canvas.height;
  
    if(!isSorted){
    for(let i=0; i<array.length; i++){
      context.moveTo(x, h);
      context.lineTo(x, (h - (array[i] * minHeight)));
      context.stroke();
      x += gap + width;
    }
  }
  }

  function displayNestedArray(nestedArray){
    const canvas = document.getElementById("algorithmCanvas");
    const context = canvas.getContext("2d");
    const maxX = canvas.width;
    const maxY = canvas.height;
    let gap = 2;

    context.beginPath();
    context.moveTo(0,0);
    context.clearRect(0, 0, canvas.width, canvas.height);

    context.strokeStyle = "black";
  
    let minHeight = (maxY - gap) / array.length;
    let width = (maxX  - ((array.length + 2) * gap)) / array.length ;
    context.lineWidth = width;
    let x = (width / 2) + gap + gap;
    let h = canvas.height;

    if(!isSorted){
      for(let i=0; i<nestedArray.length; i++){
        for(let y=0; y<nestedArray[i].length; y++){
          context.moveTo(x, h);
          context.lineTo(x, (h - (nestedArray[i][y] * minHeight)));
          context.stroke();
          x += gap + width;
        }
      }
    }
  }

  function displaySortedArray(interval){
    const canvas = document.getElementById("algorithmCanvas");
    const context = canvas.getContext("2d");
    const maxX = canvas.width;
    const maxY = canvas.height;
    let gap = 2;
  
    let minHeight = (maxY - gap) / array.length;
    let width = (maxX  - ((array.length + 2) * gap)) / array.length ;
    context.lineWidth = width;
    let x = (width / 2) + gap + gap;
    let h = canvas.height;

    x += index * (gap + width);

    if(index == 0){
      context.beginPath();
      context.moveTo(0,0);
      context.clearRect(0, 0, canvas.width, canvas.height);
    }

    context.strokeStyle = "green";
    context.moveTo(x, h);
    context.lineTo(x, (h - (array[index] * minHeight)));
    context.stroke();

    index++;
    if(index == array.length){
      setDisplaySorted(false);
      clearInterval(interval);
      return;
    }
  }
  
  function bubbleSort(interval) {
    let load = true;
    let returnNext = false;
    const initialIndex = index;
    let first = true;
    while(load && index != -1){
      for(let i=index; i<array.length; i++){
        if(returnNext){
          return i;
        }
        if(array[i] > array[i+1]){ // If current index is greater than the next
          let temp = array;
          let tempIndex = temp[i]
          temp[i] = temp[i+1];
          temp[i+1] = tempIndex;
          setArray(temp); 
          returnNext = true;
        }
        else if(!first && i ==initialIndex){
          load = false;
        }
        first = false;
      }
      index = 0;
    }
    index = 0;
    console.log("Bubble Sorted");
    clearInterval(interval);
    setDisplaySorted(true);
    setIsSorted(true);
    return -1;
  }

  function selectionSort(){
    let smallest = array[index];
    let smallestIndex = index;
    for(let i=index+1; i< array.length; i++){
      if(smallest > array[i]){ // if current smallest is no longer smallest
        smallest = array[i];
        smallestIndex = i;
      }
    }
    let tempArr = array;
    let temp = array[index];

    tempArr[index] = smallest;
    tempArr[smallestIndex] = temp;
    setArray(tempArr);
  }

  function insertionSort(){
    let tempArray = array;
    for(let i=index; i >= 0; i--){
      if(tempArray[i] < tempArray[i-1]){ // Current index is less than previous index
        let temp = tempArray[i-1];
        tempArray[i-1] = tempArray[i];
        tempArray[i] = temp;
      }
    }
    setArray(tempArray);
  }

  function mergeSort(mergeArr){
    for(let i=0; i< mergeArr.length-1; i++){
      if(i == mergeArr.length-2 || mergeArr[i].length == mergeArr[i+1].length){ // Next array and current array have same length
        console.log("Merging: ", mergeArr[i], " : ", mergeArr[i+1]);
        let tempArr = [];
        let arr1Index = 0;
        let arr2Index = 0;
        while(arr1Index < mergeArr[i].length || arr2Index < mergeArr[i+1].length){
          if(arr1Index >= mergeArr[i].length){ // Checks if array 1 has been pushed
            for(let y=arr2Index; y<mergeArr[i+1].length; y++){ // Adds the rest of array 2
              tempArr.push(mergeArr[i+1][y]);
            }
            break;
          }
          else if(arr2Index >= mergeArr[i+1].length){ // Checks if array 2 has been pushed
            for(let y=arr1Index; y<mergeArr[i].length; y++){ // Adds the rest of array 1
              tempArr.push(mergeArr[i][y]);
            }
            break;
          }

          if(mergeArr[i][arr1Index] < mergeArr[i+1][arr2Index] ){ // Compares current index of arr 1 and arr 2
            tempArr.push(mergeArr[i][arr1Index]);
            arr1Index++;
          }
          else{
            tempArr.push(mergeArr[i+1][arr2Index]);
            arr2Index++;
          }
        }
        mergeArr[i] = tempArr;
        mergeArr.splice(i+1, 1);
        for(let y=0; y<=i; y++){
          console.log("Array: ", y, " : ", mergeArr[y]);
        }
        console.log("\n");

        return mergeArr;
      }
    }
  }

  return (
    <div className="algorithmContainer">
      <div className='algorithmOptions'>
        <ul className='algorithmInputs'>
        <li className='algorithmInputs'>
          <label className='algorithmInputs'>Loop:</label>
          <input className='algorithmInputs' type='checkBox' checked={loop} onChange={() => {setLoop(!loop)}}/>
        </li>
        <li id='algorithmSelect' className='algorithmInputs'>
          <Dropdown className='algorithmInputs' placeholderClassName='dropDownTop' value={algorithm} options={options} onChange={(option) => {setAlgorithm(option.value); console.log(option)}} />
        </li>
        <button className='algorithmInputs' onClick={() => {
          if(!displaySorted){
            console.log("Shuffle");
            clearInterval(runAlgorithm);
            setRunAlgorithm(null);
            clearInterval(runAlgorithm);
            index = 0;

            setIsSorted(false);
            setArray(shuffle(size));
          }
        }}>Shuffle</button>
        </ul>
      </div>

      <div>
        <canvas id="algorithmCanvas" width="1000" height="800">
          Your browser dose not support canvas.
        </canvas>
      </div>
    </div>
  ); 
}

export default Algorithms;